import { Injectable, Injector } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { flatMap, groupBy, isNil, isNumber } from 'lodash-es';
import {
  combineLatest,
  filter,
  first,
  map,
  mergeMap,
  Observable,
  of,
  skipWhile,
  switchMap,
} from 'rxjs';

import { ISelectGroupOption } from '@locumsnest/components';
import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';
import { distinctCollectionUntilChangedByKey } from '@locumsnest/core/src/lib/ngrx/operators';

import { PRODUCT_CODES } from '../../core/constants';
import { PROFESSION_SPECIALTY_SERVICE_TOKEN } from '../../core/services/state/opaque-tokens';
import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import { ISpecialtyEntity } from '../../interfaces/api/specialty-entity';
import { ISubSpecialtyEntity } from '../../interfaces/api/sub-specialty-entity';
import { SubSpecialtyService } from '../../sub-specialty/+state/sub-specialty.service';
import { distinctCollectionUntilChangedByLength } from './../../../../../../libs/core/src/lib/ngrx/operators/index';
import { IProfessionSpecialtyEntity } from './../../interfaces/api/profession-specialty-entity';
import { IProfessionSpecialtyService } from './interfaces/interfaces';
import {
  ProfessionPaginationMessages,
  ResetProfessionPaginationMessage,
  UpsertMultipleMessage,
  UpsertMultiplePagesMessage,
  UpsertProfessionPageMessage,
} from './profession.messages';
import { ProfessionPersistenceService } from './profession.persistence.service';
import {
  professionPaginationSelectors,
  selectActiveProfessionOptions,
  selectProfessionByCode,
  selectProfessionEntityState,
  selectProfessionNamesByIds,
} from './profession.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProfessionService extends FilterStateService<
  IProfessionEntity,
  UpsertProfessionPageMessage,
  ResetProfessionPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
    PRODUCT_CODES.PASSPORT_PLUS,
  ];

  constructor(
    protected store: Store,
    protected persistenceService: ProfessionPersistenceService,
    protected subSpecialtyService: SubSpecialtyService,
    protected microAppService: MicroAppService,
    protected injector: Injector,
  ) {
    super();
  }

  get paginationMessages() {
    return ProfessionPaginationMessages;
  }

  get upsertMessageClass() {
    return UpsertProfessionPageMessage;
  }
  get clearMessageClass() {
    return ResetProfessionPaginationMessage;
  }

  get upsertMultiplePagesMessage() {
    return UpsertMultiplePagesMessage;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get paginationSelectors() {
    return professionPaginationSelectors;
  }

  get entityStateSelector() {
    return selectProfessionEntityState;
  }

  get professionSpecialtyService(): IProfessionSpecialtyService {
    return this.injector.get(PROFESSION_SPECIALTY_SERVICE_TOKEN);
  }

  getAllActive() {
    return this.getAll().pipe(map((professions) => professions.filter(({ active }) => active)));
  }

  getProfessionNamesByIds(professionIds$: Observable<number[]>) {
    return professionIds$.pipe(
      mergeMap((professionIds) =>
        this.store.pipe(select(selectProfessionNamesByIds(professionIds))),
      ),
    );
  }

  getActiveProfessionOptions() {
    return this.store.pipe(
      select(selectActiveProfessionOptions),
      filter((options) => options && options.length > 0),
    );
  }

  getProfessionOptionsWithSkipOption() {
    return this.getActiveProfessionOptions();
    // .pipe(
    //   map((options)=>[...options,{id: -1, name:'Skip for now'}])
    // );
  }

  fetch() {
    return this.loadAllPages('default', null);
  }

  mergeProfessionWithSpecialtyEntities<P extends IProfessionEntity | number>([
    profession,
    subSpecialties,
    professionSpecialties,
  ]: [P, ISubSpecialtyEntity[], IProfessionSpecialtyEntity[]]) {
    return subSpecialties
      .map((specialty) => {
        const parentProfessionId = isNumber(profession)
          ? profession
          : (profession as IProfessionEntity).id;
        const professionSpecialty = professionSpecialties.find(
          ({ profession: professionId, specialty: specialtyId }) =>
            professionId === parentProfessionId && specialtyId === specialty.id,
        );
        return {
          ...professionSpecialty,
          specialty,
          profession,
        };
      })
      .filter(({ id }) => !isNil(id));
  }

  mergeProfessionsWithSpecialtyEntities<P extends IProfessionEntity | number>([
    professions,
    subSpecialties,
    professionSpecialties,
  ]: [P[], ISubSpecialtyEntity[], IProfessionSpecialtyEntity[]]) {
    return flatMap(professions, (profession) =>
      this.mergeProfessionWithSpecialtyEntities([
        profession,
        subSpecialties,
        professionSpecialties,
      ]),
    );
  }

  getAllIdsAfterLoading() {
    return this.getAllAfterLoading().pipe(
      distinctCollectionUntilChangedByKey('id'),
      map((professions) => professions.map(({ id }) => id)),
    );
  }

  getProfessionByCode(code: string) {
    return this.store.pipe(select(selectProfessionByCode(code)));
  }

  getProfessionSpecialtyEntities() {
    return combineLatest([
      this.getAllAfterLoading(),
      this.subSpecialtyService.getAllAfterLoading(),
      this.professionSpecialtyService.getAllAfterLoading(),
    ]).pipe(map((entities) => this.mergeProfessionsWithSpecialtyEntities(entities)));
  }

  getProfessionSpecialtiesByCategory<P extends IProfessionEntity | number>(
    profession: P,
    categoryId: number,
  ) {
    return combineLatest([
      of([profession]),
      this.subSpecialtyService.getAllByCategory(categoryId),
      this.professionSpecialtyService.getAllAfterLoading(),
    ]).pipe(map((entities) => this.mergeProfessionsWithSpecialtyEntities(entities)));
  }

  getActiveProfessionSpecialtiesByCategory<P extends IProfessionEntity | number>(
    profession: P,
    categoryId: number,
  ) {
    return this.getProfessionSpecialtiesByCategory(profession, categoryId).pipe(
      map((professionSpecialties) => professionSpecialties.filter(({ active }) => active)),
    );
  }

  getProfessionSpecialtyOption(
    professionSpecialty: IProfessionSpecialtyEntity<ISubSpecialtyEntity, IProfessionEntity>,
  ): ISelectGroupOption & { categoryId: number } {
    return {
      id: professionSpecialty.id,
      name: professionSpecialty.specialty.title,
      category: professionSpecialty.profession.title,
      categoryId: professionSpecialty.profession.id,
      disabled: !professionSpecialty.active,
    };
  }

  getProfessionSpecialtyOptionByCategory(
    professionSpecialty: IProfessionSpecialtyEntity<
      ISubSpecialtyEntity<ISpecialtyEntity>,
      IProfessionEntity
    >,
  ): ISelectGroupOption {
    return {
      id: professionSpecialty.id,
      name: professionSpecialty.specialty.title,
      category: professionSpecialty.specialty.category?.title,
      disabled: !professionSpecialty.active,
    };
  }

  getProfessionSpecialtyOptionsByCategory(
    professionIds: number[] = [],
    selection$?: Observable<number> | Observable<number[]>,
  ): Observable<ISelectGroupOption[]> {
    return this.getProfessionSpecialtyRawOptions(professionIds, selection$).pipe(
      switchMap((professionSpecialties): Observable<ISelectGroupOption[]> => {
        if (!professionSpecialties.length) {
          return of([]);
        }
        return combineLatest(
          professionSpecialties.map((professionSpecialty) =>
            this.professionSpecialtyService
              .addCategory(professionSpecialty)
              .pipe(
                map((professionSpecialtyWithCategory) =>
                  this.getProfessionSpecialtyOptionByCategory(professionSpecialtyWithCategory),
                ),
              ),
          ),
        ).pipe(first());
      }),
    );
  }

  getProfessionSpecialtyOptions(
    professionIds: number[] = [],
    selection$?: Observable<number> | Observable<number[]>,
    exclude$?: Observable<number> | Observable<number[]>,
  ) {
    return this.getProfessionSpecialtyRawOptions(professionIds, selection$, exclude$).pipe(
      map((professionSpecialties) =>
        professionSpecialties.map((professionSpecialty) =>
          this.getProfessionSpecialtyOption(professionSpecialty),
        ),
      ),
    );
  }

  getProfessionSpecialtyOptionsDict(
    professionIds: number[] = [],
    selection$?: Observable<number> | Observable<number[]>,
    exclude$?: Observable<number> | Observable<number[]>,
  ): Observable<Record<number, ISelectGroupOption[]>> {
    return this.getProfessionSpecialtyOptions(professionIds, selection$, exclude$).pipe(
      map((o) => groupBy(o, 'categoryId')),
    );
  }

  private getProfessionSpecialtyRawOptions(
    professionIds: number[] = [],
    selection$?: Observable<number> | Observable<number[]>,
    exclude$?: Observable<number> | Observable<number[]>,
  ): Observable<IProfessionSpecialtyEntity<ISubSpecialtyEntity, IProfessionEntity>[]> {
    const activeProfessionSpecialties$ = professionIds?.length
      ? this.professionSpecialtyService.getAllActiveWithDetailsForProfessions(professionIds)
      : this.professionSpecialtyService.getAllActiveWithDetails();
    selection$ ??= of([]);
    const excludedProfessionSpecialties$ = exclude$
      ? (exclude$ as Observable<number | number[]>).pipe(
          map((selection) => (Array.isArray(selection) ? selection : [selection])),
        )
      : of<number[]>([]);

    const selectedInactiveProfessionSpecialties$ = (
      selection$ as Observable<number | number[]>
    ).pipe(
      switchMap((selection) => {
        if (!Array.isArray(selection)) {
          selection = [selection];
        }
        selection = selection.filter((s) => !isNil(s));
        if (selection.length) {
          return combineLatest(
            selection.map((selectedId) =>
              this.professionSpecialtyService.getOneWithDetails(selectedId),
            ),
          );
        }
        return of<IProfessionSpecialtyEntity<ISubSpecialtyEntity<number>, IProfessionEntity>[]>([]);
      }),
      map((professionSpecialties) => professionSpecialties.filter(({ active }) => !active)),
      distinctCollectionUntilChangedByLength(),
    );
    return combineLatest([
      activeProfessionSpecialties$,
      selectedInactiveProfessionSpecialties$,
      excludedProfessionSpecialties$,
    ]).pipe(
      map(([activeProfessionSpecialties, selectedInactiveProfessionSpecialties, excluded]) => {
        const professionSpecialties = [
          ...activeProfessionSpecialties,
          ...selectedInactiveProfessionSpecialties,
        ];
        if (excluded.length) {
          return professionSpecialties.filter((ps) => !excluded.includes(ps.id));
        }
        return professionSpecialties;
      }),
      skipWhile(
        (professionSpecialties) =>
          professionSpecialties.filter(
            (professionSpecialty) =>
              !(professionSpecialty.profession && professionSpecialty.specialty),
          ).length > 0,
      ),
    );
  }
}
