import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { ISelectOption } from '@locumsnest/components/src/lib/interfaces';

import { IProfessionEntity } from '../../interfaces/api/profession-entity';
import {
  IProfessionEntityState,
  IProfessionFeatureState,
  IProfessionSharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './profession.adapter';
import { selectAll } from './profession.reducer';

export const getEntityState = (state: { entityState: IProfessionEntityState }) => state.entityState;

export const getProfessionNamesByIds =
  (professionIds: number[]) => (professions: IProfessionEntity[]) =>
    professions
      .filter((x) => professionIds.indexOf(x.id) >= 0)
      .map((profession) => profession.title);

export const getProfessionByCode = (code: string) => (professions: IProfessionEntity[]) =>
  professions.find((profession) => profession.code === code);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectProfessionState = createFeatureSelector<
  IProfessionFeatureState & IProfessionSharedState
>('professions');

export const selectProfessionEntityState = createSelector(selectProfessionState, getEntityState);

export const selectAllProfessions = createSelector(selectProfessionEntityState, selectAll);

export const selectActiveProfessionOptions = createSelector(selectAllProfessions, (professions) =>
  professions
    .filter((profession) => profession.active)
    .map((profession) => ({ id: profession.id, name: profession.title }) as ISelectOption)
    .sort((a, b) => +get(a, 'disabled', false) - +get(b, 'disabled', false)),
);

export const selectProfessionNamesByIds = (professionIds: number[]) =>
  createSelector(selectAllProfessions, getProfessionNamesByIds(professionIds));

export const selectProfessionByCode = (code: string) =>
  createSelector(selectAllProfessions, getProfessionByCode(code));

export const professionPaginationSelectors = paginationAdapter.paginationSelectors(
  selectProfessionState,
  loadingAdapter,
  'pagination',
  'entityState',
  false,
);
